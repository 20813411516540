header {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.main {
  padding-top: 64px;
}

.sc-bwzfXH {
  background: #e75113;
  color: #fff;
}

@media screen and (min-width: 900px) {
  .sc-bwzfXH {
    display: flex !important;
    justify-content: center;
  }
}

.sc-bwzfXH ul {
  margin: 0;
  margin-left: -40px;
  display: flex;
  align-items: center;
}

.sc-bwzfXH li {
  list-style: none;
  font-size: 12px;
  max-width: 250px;
}

.sc-bwzfXH li a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 16px;
  height: 68px;
}

.sc-bwzfXH li a:hover {
  background: #828181;
}

.sc-bdVaJa {
  background: #e75113;
  min-height: 64px;
}

.sc-bdVaJa > div:first-child {
  position: absolute;
  top: 20px;
  right: 24px;
  color: white;
}

.sc-bdVaJa > div:nth-child(2) {
  padding-top: 64px;
}

.sc-bdVaJa > div:nth-child(2) > ul {
  margin-left: -40px;
}

.sc-bdVaJa > div:nth-child(2) > ul > li {
  list-style: none;
  padding: 16px;
  color: white;
}

.sc-bdVaJa > div:nth-child(2) > ul > li > a {
  text-decoration: none;
}

.sc-bdVaJa > div:nth-child(2) > ul > li:hover {
  background: #828181;
}
